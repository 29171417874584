const jwtDecode = require("jwt-decode");

class UserTokenHelper {
  USER_TOKEN = "user-token";
  RETURN_URL = "return-url";

  saveUserToken(token) {
    localStorage.setItem(this.USER_TOKEN, token);
  }
  getUserToken() {
    return localStorage.getItem(this.USER_TOKEN);
  }
  removeUserToken() {
    localStorage.removeItem(this.USER_TOKEN);
    localStorage.removeItem("organizationData");
  }
  getUserName() {
    const user = this.getUser();
    if (user) {
      return user.full_name;
    }
    return "";
  }
  getUserDocument() {
    const user = this.getUser();
    if (user) {
      return user.user_name;
    }
    return "";
  }
  getUser() {
    const token = this.getUserToken();
    if (token) {
      return jwtDecode(token);
    }
    return null;
  }
  getUserURL() {
    const user = this.getUser();
    if (user) {
      return user.url;
    }
  }

  hasValidToken() {
    let valid = false;
    let user = this.getUser();
    if (user) {
      let exp = new Date();
      exp.setTime(user.exp * 1000);
      if (new Date() < exp) {
        valid = true;
      }
    }
    return valid;
  }

  saveReturnUrl(fullPath) {
    localStorage.setItem(this.RETURN_URL, fullPath);
  }

  getReturnUrl() {
    let returnUrl = localStorage.getItem(this.RETURN_URL);
    localStorage.removeItem(this.RETURN_URL);
    return returnUrl;
  }

  handleTokenResponse(response, router) {
    let urlRouter = { name: "/"};    
    let newToken = response.data;
    this.saveUserToken(newToken);
    let userURL = this.getUserURL();
    if (userURL && !window.location.host.includes(userURL)) {
      //Redireciona para a URL (sisbraip ou embarque) que o usuário tem acesso
      //Se usuário tem acesso aos dois sistemas o userURL vem vazio
      this.removeUserToken();
      window.location = `http://${userURL}/sisbraip/auth/login-return?token=${newToken}`;
    } else {
      const returnUrl = this.getReturnUrl();
      if (returnUrl) {
        urlRouter = returnUrl;
      }
      router.push(urlRouter);
    }
  }
}

export default new UserTokenHelper();
